import React, {  } from "react";
import "./App.css";
import "materialize-css";
import { Row, Col } from "react-materialize";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Portfolio from "./components/Portfolio";
import Nav from "./components/Nav";
import SideNav from "./components/SideNav.jsx";
import Wystawy from "./components/Wystawy.jsx";
import Wystawa1 from "./components/Wystawa1.jsx";
import Wystawa2 from "./components/Wystawa2.jsx";
import Wystawa3 from "./components/Wystawa3.jsx";
import Wystawa4 from "./components/Wystawa4.jsx";
import Artists from "./components/Artists";
import sklep from "./components/sklep";
import admin from "./components/admin.jsx";
import adminWystawy from "./components/adminWystawy";
import DocumentMeta from 'react-document-meta';

function App() {
  const meta = {
    title: 'ArtStrefa43',
    description: 'Galeria sztuki rynek glowny',
    canonical: 'https://galeriarg43.netlify.app/home',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'sztuka, krakow, galeria, plakaty, artstrefa 43'
      }
    }
  };
  return (<DocumentMeta {...meta}>
    <div className="App">
      <Row>
        <Col s={12} m={12} l={12} push="" className="">
          <BrowserRouter>
            <Nav />

            <SideNav />
            <Switch>
              <Route path="/home" component={Home} />
              <Route path="/contact" component={Contact} />
              <Route path="/admin" component={admin} />
              <Route path="/sklep" component={sklep} />
              <Route path="/adminWystawy" component={adminWystawy} />
              <Route path="/portfolio" component={Portfolio} />
              <Route path="/wystawa1" component={Wystawa1} />
              <Route path="/wystawa2" component={Wystawa2} />
              <Route path="/wystawa3" component={Wystawa3} />
              <Route path="/wystawa4" component={Wystawa4} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/artysci" component={Artists} />
              <Route path="/wystawy" component={Wystawy} />
              <Route path="/:any" component={Home} />
              <Route path="/" component={Home} />
            </Switch>
          </BrowserRouter>
        </Col>
      </Row>
    </div></DocumentMeta>
  );
}

export default App;
