import db from "./firebase.config";
import React, { useState, useEffect } from "react";
import "materialize-css";
import {
  Modal,
  Card,
  Col,
  Row,
  Collapsible,
  CollapsibleItem,
} from "react-materialize";
import Button from "react-materialize/lib/Button";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/database";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";

const Write = () => {
  const [info, setInfo] = useState([]);
  const [name, setName] = useState();
  const [artist, setArtist] = useState();
  const [picture, setPicture] = useState();
  const [description, setDescription] = useState();
  const [price, setPrice] = useState();
  const [size, setSize] = useState();
  // const [loggedin, setLoggedin] = useState();
  const [pass, setPass] = useState();
  const [buy, setBuy] = useState();
  const [rezerwacja, setRezerwacja] = useState();

  const login = () => {
    localStorage.setItem("pass", pass);
    window.location.reload(false);
  };
  let loggedin = localStorage.getItem("pass");
  const Fetchdata = async () => {
    await db
      .collection("data")
      .get()
      .then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
        db.collection("data")
          .get()
          .then((snap) => {
            setSize(snap.size); // will return the collection size
          });
      });
  };
  useEffect(() => {
    setTimeout(() => {
      Fetchdata();
    }, 1); //miliseconds
  }, []);
  const doKupienia = async () => {
    const ref = doc(db, "data", name);
    await setDoc(
      ref,
      {
        // NAME: name,
        BUY: "Kup",
        REZERWACJA: false,
      },
      { merge: true }
    );

    window.location.reload(false);
  };
  const Rezerwacja = async () => {
    const ref = doc(db, "data", name);
    await setDoc(
      ref,
      {
        // NAME: name,
        BUY: "Rezerwacja",
        REZERWACJA: true,
      },
      { merge: true }
    );

    window.location.reload(false);
  };
  const Sprzedane = async () => {
    const ref = doc(db, "data", name);
    await setDoc(
      ref,
      {
        // NAME: name,
        BUY: "Sprzedany",
        REZERWACJA: true,
      },
      { merge: true }
    );

    window.location.reload(false);
  };
  const updateDATA = async () => {
    const ref = doc(db, "data", name);
    await setDoc(
      ref,
      {
        NAME: name,
        ARTIST: artist,
        PICTURE: picture,
        DESCRIPTION: description,
        PRICE: price,
        BUY: buy,
      },
      { merge: true }
    );

    window.location.reload(false);
  };

  const createDATA = async () => {
    const ref = doc(db, "data", name);
    await setDoc(ref, {
      NAME: name,
      ARTIST: artist,
      PICTURE: picture,
      DESCRIPTION: description,
      PRICE: price,
      id: -size - 1,
      BUY: "Kup",
    });
    window.location.reload(false);
  };
  const deleteDATA = async () => {
    await deleteDoc(doc(db, "data", name));
    window.location.reload(false);
  };

  const items = info.map((data) => {
    return (
      <Row>
        <Col s={12} m={1}>
          {data.BUY}
          <Collapsible className="z-depth-0" accordion>
            <CollapsibleItem
              expanded={false}
              header={
                <Row>
                  <Button
                    onClick={(e) => setName(e.target.value)}
                    value={data.NAME}
                  >
                    zmien
                  </Button>
                </Row>
              }
              node="div"
            >
              <Button
                onClick={
                  ((e) => {
                    setName(e.target.value);
                  },
                  doKupienia)
                }
                value={data.NAME}
                className="green"
              >
                Kup
              </Button>
              <Button
                onClick={
                  ((e) => {
                    setName(e.target.value);
                  },
                  Rezerwacja)
                }
                value={data.NAME}
                className="orange"
              >
                Rezerwacja
              </Button>
              <Button
                onClick={
                  ((e) => {
                    setName(e.target.value);
                  },
                  Sprzedane)
                }
                value={data.NAME}
                className="red"
              >
                Sprzedany
              </Button>
            </CollapsibleItem>
          </Collapsible>
        </Col>

        <Col s={12} m={2}>
          {data.NAME}
        </Col>
        <Col s={12} m={2}>
          {data.ARTIST}
        </Col>
        <Col s={12} m={2}>
          <img src={data.PICTURE} width="100%" />
        </Col>
        <Col s={12} m={2}>
          {data.DESCRIPTION}
        </Col>

        <Col s={2} m={1}>
          {data.PRICE} PLN
        </Col>
        <Col s={12} m={2}>
          <Modal
            actions={[
              <Button flat modal="close" node="button" waves="green">
                Close
              </Button>,
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header={data.NAME}
            id={data.NAME}
            value={data.NAME}
            open={false}
            options={{
              dismissible: true,
              endingTop: "10%",
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: "4%",
            }}
            trigger={
              <Button
                value={data.NAME}
                className="red"
                node="button"
                onClick={(e) => setName(e.target.value)}
              >
                <Button
                  value={data.NAME}
                  className="red"
                  node="button"
                  onClick={(e) => {
                    setName(e.target.value);
                    setPrice(data.PRICE);
                    setDescription(data.DESCRIPTION);
                    setPicture(data.PICTURE);
                    setArtist(data.ARTIST);
                    setBuy(data.BUY);
                  }}
                >
                  Edytuj
                </Button>
              </Button>
            }
          >
            <h5>
              {name} / {artist} / {description} / {price} pln / {picture}
            </h5>

            <input
              placeholder="ARTIST"
              onChange={(e) => setArtist(e.target.value)}
            />

            <input
              placeholder="DESCRIPTION"
              onChange={(e) => setDescription(e.target.value)}
            />

            <input
              placeholder="PRICE"
              onChange={(e) => setPrice(e.target.value)}
            />

            <input
              placeholder="PICTURE"
              onChange={(e) => setPicture(e.target.value)}
            />

            <Button onClick={updateDATA}>Zapisz</Button>
          </Modal>
        </Col>
      </Row>
    );
  });
  if (loggedin == "Haslo2022") {
    return (
      <Row>
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Col s={12} l={8} push="l2">
          <Row>
            <Card>
              <h5>
                Dodaj nowy plakat: {name} / {artist} / {description} / {price}{" "}
                pln / {picture}
              </h5>
              <input
                placeholder="Nazwa "
                onChange={(e) => setName(e.target.value)}
              />
              <input
                placeholder="Artysta"
                onChange={(e) => setArtist(e.target.value)}
              />

              <input
                placeholder="Opis"
                onChange={(e) => setDescription(e.target.value)}
              />

              <input
                placeholder="Cena"
                onChange={(e) => setPrice(e.target.value)}
              />

              <input
                placeholder="Zdjecie"
                onChange={(e) => setPicture(e.target.value)}
              />
              <Button className="red" onClick={createDATA}>
                Dodaj
              </Button>
            </Card>
          </Row>
          <Row>
            <Card>
              <h5>Podaj plakat do usuniecia:</h5>

              <input
                placeholder="Nazwa"
                onChange={(e) => setName(e.target.value)}
              />
              <Button className="red" onClick={deleteDATA}>
                Usun
              </Button>
            </Card>
          </Row>
          <Row className="white">
            <Col s={12}>{items}</Col>
          </Row>
        </Col>
      </Row>
    );
  } else
    return (
      <Row>
        <Col s={10} l={6} push="s1 l3">
          <Row />
          <Row />
          <Row />
          <Row />
          <Row /> <Row />
          <Row />
          <Row />
          <Card>
            <input
              type="password"
              placeholder="haslo"
              onChange={(e) => setPass(e.target.value)}
            />
            <Button className="red" onClick={login}>
              Zaloguj
            </Button>
          </Card>
        </Col>
      </Row>
    );
};

export default Write;
