import "materialize-css";
import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Card,
  MediaBox,
  Caption,
  Modal,
  Table,
} from "react-materialize";
import Aos from "aos";
import "aos/dist/aos.css";
import Lista from "./ListaWystaw";
import Iframe from "react-iframe";
import db from "./firebase.config";
import { Link } from "react-router-dom";

const Wystawa1 = () => {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      Fetchdata();
    }, 1); //miliseconds
  }, []);
  //  window.addEventListener("load", () => {
  //     Fetchdata();
  //   });

  // Fetch the required data using the get() method
  const Fetchdata = async () => {
    await db
      .collection("wystawy")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
      });
  };

  const items = info.map((data) => {
    return (
      <Row>
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Col s={12} m={8} push="m2">
          <div data-aos="fade-left" data-aos-duration="3000" className=" ">
            <Row>
              <Col s={12} m={7}>
                <Row />
                <Row />

                <img src={`${data.PICTURE1}`} width="100%" />
              </Col>
              <Col s={12} m={5} className="left-align">
                <h3>{data.WYSTAWA1}</h3>
                <h5>{data.AUTHOR1}</h5>
                <br />
                {data.DATE1}

                <Divider />
                <Row />
                <div className="left-align">{data.OPISSHORT1}</div>
              </Col>
            </Row>
            <Row />
            <Row />
            <Row />
            <Row />

            <Row>
              <Col s={12} m={6} l={4}>
                
                  <MediaBox
                    id="11"
                    options={{
                      inDuration: 275,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 200,
                    }}
                  >
                    <img
                      alt=""
                      src={data.PICTURE11}
                      width="100%"
                    />
                  </MediaBox>
               
              </Col>
              <Col s={12} m={6} l={4}>
              
                  <MediaBox
                    id="12"
                    options={{
                      inDuration: 275,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 200,
                    }}
                  >
                    <img
                      alt=""
                      src={data.PICTURE12}
                      width="100%"
                    />
                  </MediaBox> 
              </Col>
                  <Col s={12} m={6} l={4}>
                
                  <MediaBox
                    id="13"
                    options={{
                      inDuration: 275,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 200,
                    }}
                  >
                    <img
                      alt=""
                      src={data.PICTURE13}
                      width="100%"
                    />
                  </MediaBox>  
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
    );
  });

  return <Row>{items}</Row>;
};

export default Wystawa1;
