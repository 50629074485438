import "materialize-css";
import React, {  useEffect } from "react";
import {
  Button,
  Col,

  Row,

  SideNav,
  SideNavItem,
} from "react-materialize";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import logo from "./svg/logo.svg";

const Nav = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  let lang = localStorage.getItem("lang");

  if (lang == "en") return <Row></Row>;
  else
    return (
      <div>
        <div className="navbar right-align hide-on-small-only">
          <Col m={10} push="m2">
            <Link to="home" className="navbaritem">
              Home
            </Link>
            <Link to="artysci" className="navbaritem">
              Artyści
            </Link>
            {/* <Link to="wystawy" className="navbaritem">
              Wystawy
            </Link> */}

            <Link to="adminWystawy" className="navbaritem">
              admin Wystawy
            </Link>

            <Link to="admin" className="navbaritem">
              Admin
            </Link>
            <Link to="sklep" className="navbaritem">
              Sklep
            </Link>
            <Link to="portfolio" className="navbaritem">
              O nas
            </Link>
            {/* <Link to="contact" className="navbaritem">
              Partnerzy
            </Link> */}
            <Link to="contact" className="navbaritem">
              Kontakt/Rezerwacje
            </Link>
          </Col>
        </div>

        <div className="hide-on-med-and-up">
          <SideNav
            id="SideNav-10"
            options={{
              draggable: true,
            }}
            trigger={
              <Button node="button" className="sideNavbtn grey darken-3 ">
                <img src={logo} width="100%" />
                <br />
                MENU
              </Button>
            }
          >
          
            <div className="grey darken-1 text-white">
              <SideNavItem href="home">
                <h5 className="white-text">Galeria RG_43</h5>
              </SideNavItem>
              <SideNavItem href="artysci">
                <h5 className="white-text">Artysci</h5>
              </SideNavItem>
              <SideNavItem href="wystawy">
                <h5 className="white-text">Wystawy</h5>
              </SideNavItem>
              <SideNavItem href="sklep">
                <h5 className="white-text">Sklep</h5>
              </SideNavItem>            
              <SideNavItem href="portfolio">
                <h5 className="white-text">O nas</h5>{" "}
              </SideNavItem><SideNavItem href="contact">
                <h5 className="white-text">Kontakt</h5>
              </SideNavItem>
              <SideNavItem href="adminWystawy">
                <h5 className="white-text">adminWystawy</h5>
              </SideNavItem>
              <SideNavItem href="admin">
                <h5 className="white-text">admin</h5>
              </SideNavItem>
              <SideNavItem href="rodo">
                <h5 className="white-text">RODO</h5>
              </SideNavItem>
              <img src={logo} width="100%" />
            </div>
          </SideNav>
        </div>
      </div>
    );
};

export default Nav;
