import "materialize-css";
import React, { useState, useEffect } from "react";
import {
  Col,
  Divider,
  Row,
  Card
} from "react-materialize";
import db from "./firebase.config";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/database";
import { doc, setDoc, getDoc, deleteDoc, collection, collections } from "firebase/firestore";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";


const Artists = () => {
  useEffect(() => {
    setTimeout(() => {
      Fetchdata();
    }, 1); //miliseconds
  }, []);
  const [info, setInfo] = useState([]);
  const Fetchdata = async () => {
    await db
      .collection("artysci")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
      });

  };
  let lang = localStorage.getItem("lang");
  const items = info.map((data) => {
    return (
      <Row>
        
      <Col s={12} m={12} l={12} push="">
      <Row/><Row/><Row/><Row/>
        <Card className="z-depth-3">
          <img className="artist" src={data.ZDJECIE} width="50%" />
          <Row className=" textMobile">
            <Col className="tabela" s={12}>
              <h5>{data.ARTYSTA}</h5>
            </Col>
            <Col className="tabela" s={12}>
              {data.OPIS}
            </Col>

            <Col className="tabela" s={12}>
              <a href={data.STRONA}>{data.STRONA}</a>
              
            </Col>
          </Row>
        </Card>
      </Col>
      </Row>
    );
  });
  return (
    <div>

      <Row>{items}</Row>
    </div>
  );
};

export default Artists;
