import "materialize-css";
import React, {   } from "react";
import {
  Col,
  Row,
  Card,
  MediaBox,
} from "react-materialize";
import "aos/dist/aos.css";
import pic2 from "./img/IMG_3430.webp"
import pic1 from "./img/IMG_7697.webp"
import pic3 from "./img/IMG_9602.webp"
import pic4 from "./img/IMG_9607.webp"
import pic5 from "./img/IMG_9614 2.webp"
import rzut from "./img/PIWNICA.svg"

const portfolio = () => {
  //   useEffect(() => {
  //     Aos.init({ duration: 1000 });
  //   }, []);
  //   let lang = localStorage.getItem("lang");
  // }
  return (
    <div>
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row />
      <Row>
        <Col s={12} m={9} push="m2">
          <Card className="exhibitionCard">
            <Row>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediavvBox_a2"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src={pic1}
                    width="100%"
                  />
                </MediaBox>
              </Col>
              <Col s={12} m={8}>
                <h3>Galeria RG_43</h3>
                <div className="left-align">
                <br/>
                <h5>
                Zapraszamy do naszej galerii sztuki zlokalizowanej w piwnicy kamienicy “Pod Słońcem” znajdującej się przy Rynku Głównym 43 w Krakowie. Bogata historia budynku i ich właścicieli, szczególnie rodziny Wiskida, łączy to miejsce ze sztuką polską i międzynarodową. W jednej z sal znajdą Państwo jedyne zachowane przedproże pod Rynkiem Głównym.
<br/><br/>
Coś o działalnością galerii i jej profilu
</h5>
                </div>
              </Col>
            </Row>
            <Row>
            <Col s={12} m={4}>
                <MediaBox
                  id="MediaqqBox_s2"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src={pic2}
                    width="100%"
                  />
                </MediaBox>
               
              </Col>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBdwqox_32"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src={pic3}
                    width="100%"
                  />
                </MediaBox>
                
              </Col>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBox_d2"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src={pic4}
                    width="100%"
                  />
                </MediaBox>
                
              </Col>
              <Col s={12} m={12}>
                <Row/>
                <MediaBox
                  id="MediaBox_232"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src={rzut}
                    width="100%"
                  />
                </MediaBox>
                <h5>Rzut lokalu</h5>
              </Col>
            </Row>
          </Card>
        </Col>
        <Row>
          
        </Row>
      </Row>
      <Row>
        <Col s={12} m={9} push="m2">
          <Card className="exhibitionCard">
            <Row>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBowe3_122"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src="https://materializecss.com/images/sample-1.jpg"
                    width="100%"
                  />
                </MediaBox>
              </Col>
              <Col s={12} m={8}>
                <h3>Sala nr 1</h3>
                <div className="left-align">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  <li>14 x 50cm x 70cm</li>
                  
                </div>
               
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col s={12} m={9} push="m2">
          <Card className="exhibitionCard">
            <Row>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBorrrrx_22222"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src="https://materializecss.com/images/sample-1.jpg"
                    width="100%"
                  />
                </MediaBox>
              </Col>
              <Col s={12} m={8}>
                <h3>Sala nr 2</h3>
                <div className="left-align">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  <li>18 x 50cm x 70cm</li>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col s={12} m={9} push="m2">
          <Card className="exhibitionCard">
            <Row>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBo4x_2dsa"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src="https://materializecss.com/images/sample-1.jpg"
                    width="100%"
                  />
                </MediaBox>
              </Col>
              <Col s={12} m={8}>
                <h3>Sala nr 3</h3>
                <div className="left-align">
                <div className="left-align">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  <li>27 x 50cm x 70cm</li>
                 
                </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col s={12} m={9} push="m2">
          <Card className="exhibitionCard">
            <Row>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBodex_dqwg2"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src="https://materializecss.com/images/sample-1.jpg"
                    width="100%"
                  />
                </MediaBox>
              </Col>
              <Col s={12} m={8}>
                <h3>Sala nr 4</h3>
                <div className="left-align">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  <li>18 x 50cm x 70cm</li>
                  <li>ekspozycja stojaca</li>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col s={12} m={9} push="m2">
          <Card className="exhibitionCard">
            <Row>
              <Col s={12} m={4}>
                <MediaBox
                  id="MediaBoxde_asdd122"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/389673012.jpg?k=f1828ee0883a6dfee56d1eeab54631fe27532074ccc28f870265516f7121d6e3&o=&hp=1"
                    width="100%"
                  />
                </MediaBox>
                <MediaBox
                  id="MediaBoxde_asddas112"
                  options={{
                    inDuration: 275,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 200,
                  }}
                >
                  <img
                    alt=""
                    src="https://a0.muscache.com/im/pictures/miso/Hosting-710509476889449302/original/e85c3767-e893-473f-a3c7-f36e2e035c16.jpeg?im_w=1440"
                    width="100%"
                  />
                </MediaBox>
              </Col>
              <Col s={12} m={8}>
                <h3>Dodaktowo</h3>
                <div className="left-align">
                  <h5>Kawiarnia</h5> 
                  Na parterze możecie Państwo odwiedzić Kawiarnię Gehanowska43 
                  <h5>Apartament</h5> 
                  W budynku znaduje się piękny apartamaent na wynajem z widokiem na Rynek Główny
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default portfolio;
