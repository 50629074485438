import React, { useState, useEffect } from "react";
import "materialize-css";
import {
  Col,
  Row,
  Card,
  Button,
  Modal,
  MediaBox,
  Divider,
  ProgressBar,
  TextInput,
} from "react-materialize";
import { Link } from "react-router-dom";
import db from "./firebase.config";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/database";
import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  collection,
  collections,
} from "firebase/firestore";
import emailjs from "emailjs-com";

function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_a1wwmva",
      "template_jnm4g0d",
      e.target,
      "user_x9kPpW1JmhYVbwVWmxAVD"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}
// onSubmit={sendEmail}
const Read = () => {
  const [info, setInfo] = useState([]);
  const [buy, setBuy] = useState();
  const [name, setName] = useState();
  const [correct, setCorrect] = useState();
  const [rezerwacja, setRezerwacja] = useState();
  const [description, setDescription] = useState();
  const [i, setI] = useState();
  const [size, setSize] = useState();
  const [lastDoc, setLastDoc] = useState();
  const [search, setSearch] = useState();
  const [tosearch, setTosearch] = useState();
  const [filter, setFilter] = useState();

  useEffect(() => {
    if (tosearch == null) {
      setTimeout(() => {
        Fetchdata();
      }, 1); //miliseconds
    }
  }, []);

  const Search = async () => {
    await db
      .collection("data")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
      });
    setCorrect(true);
    setTosearch(search);
  };

  const kup = async () => {
    await db
      .collection("data")
      .limit(12)
      .orderBy("id")
      .get()

      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
          const lastDoc = data.NAME;
          setLastDoc(lastDoc);
        });
      });
    setCorrect(true);
    db.collection("...")
      .get()
      .then((snap) => {
        setSize(snap.size); // will return the collection size
      });
    setI(12);
  };
  const Fetchdata = async () => {
    await db
      .collection("data")
      .limit(12)
      .orderBy("id")
      .get()

      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
          const lastDoc = data.NAME;
          setLastDoc(lastDoc);
        });
      });
    setCorrect(true);
    db.collection("...")
      .get()
      .then((snap) => {
        setSize(snap.size); // will return the collection size
      });
    setI(12);
  };
  const FetchMoredata = async () => {
    if (size <= i)
      await db
        .collection("data")
        .orderBy("id")
        .startAfter(i)
        .limit(12)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((element) => {
            var data = element.data();
            setInfo((arr) => [...arr, data]);
          });
        });
    setCorrect(true);
    setI(i + 12);
  };

  const items = info
    .filter(
      (data) => {
        if (tosearch == null) return data;
        else if (
          data.NAME.toLowerCase().includes(tosearch.toLowerCase()) ||
          data.ARTIST.toLowerCase().includes(tosearch.toLowerCase())
        ) {
          return(data) ;
        }
      }
      //   .filter(
      //     function (data,indx) {
      //         return indx%2 != 1
      //     }
      // )
    )
    .map((data) => {
      // items = info.map((data) => {
      return (
        <Col s={12} m={6} l={3} push="">
          <Card className="z-depth-3">
            <MediaBox
              id={data.NAME}
              options={{
                inDuration: 275,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 200,
              }}
            >
              <img src={data.PICTURE} width="100%" />
            </MediaBox>
            <Row className=" textMobile">
              <Col className="tabela" s={12}>
                <h5>{data.NAME}</h5>
              </Col>
              <Col className="tabela" s={12}>
                {data.ARTIST}
              </Col>

              <Col className="tabela" s={12}>
                {data.DESCRIPTION}
              </Col>
              <Col className="tabela" s={12}>
                <h5>{data.PRICE} PLN</h5>
              </Col>
              <Col className="tabela" s={12}>
                <Modal
                  actions={[
                    <Button flat modal="close" node="button" waves="green">
                      Close
                    </Button>,
                  ]}
                  bottomSheet={false}
                  fixedFooter={false}
                  id={data.NAME}
                  value={data.NAME}
                  open={false}
                  options={{
                    dismissible: true,
                    endingTop: "10%",
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: "5%",
                  }}
                  trigger={
                    <Button
                      value={data.NAME}
                      disabled={data.REZERWACJA}
                      className="red"
                      node="button"
                      onClick={(e) => setName(e.target.value)}
                    >
                      <Button
                        value={data.NAME}
                        disabled={data.REZERWACJA}
                        className="red"
                        node="button"
                        onClick={(e) => {
                          setName(e.target.value);
                          setBuy("Rezerwacja");
                        }}
                      >
                        {data.BUY}
                      </Button>
                    </Button>
                  }
                >
                  <div className="center">
                    <br />

                    <form className="contact-form" onSubmit={sendEmail}>
                      <label>Kupujesz:</label>
                      <input value={data.NAME} type="text" name="user_name" />
                      <label>Twoj Email</label>
                      <input type="email" name="user_email" />
                      <label>Adres dostawy</label>
                      <textarea name="message" /> <br /> <br />
                      <input
                        onClick={(e) => {
                          setBuy("Rezerwacja");
                          setCorrect(false);
                        }}
                        className="btn"
                        type="submit"
                        value="Potwierdzam Dane Dostawy"
                      ></input>
                    </form>
                    <br />
                    <h5>{`${data.PRICE}` + 15} PLN (15 PLN dostawa Polska)</h5>
                    <h5>
                      Dla dostawy poza Polskę prosimy o kontakt
                      <Divider />
                    </h5>
                    <h5>Numer konta do przelewu: 00 1234 1234 1234 1234</h5>
                    <h5>
                      Tytuł przelewu: {data.NAME}, {data.ARTIST}
                    </h5>
                    <Button disabled={correct} onClick={kup}>
                      Kupuje
                    </Button>
                  </div>
                </Modal>
              </Col>
            </Row>
          </Card>
        </Col>
      );
    });

  if (info.length === 0) {
    return (
      <Row>
        <ProgressBar />
      </Row>
    );
  } else
    return (
      <Row className="white textMobile">
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row>
          <Col s={10} m={5} push="s1 m3" className="right-align">
            <input
              onChange={(e) => setSearch(e.target.value)}
              id="Search"
              placeholder="Key word/Słowo kluczowe"
              type="text"
              name="user_name"
            />
          </Col>
          <Col s={10} m={4} push="s1 m3" className="left-align">
            <Button
              className="grey darken-2"
              large
              onClick={(e) => {
                Search();
              }}
            >
              Search/Wyszukaj
            </Button>
          </Col>
        </Row>
        <Row>
          <Col s={12} m={10} push="s1, m2">
            {items}
          </Col>
        </Row>
        <br />
        <Button
          large
          onClick={(e) => {
            FetchMoredata();
          }}
        >
          Wiecej
        </Button>
      </Row>
    );
};

export default Read;
