import "materialize-css";
import React, { Component, useEffect } from "react";
import { Button, Col, Divider, Row } from "react-materialize";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import logo from "./svg/logo.svg";
import ListaWystaw from "./ListaWystaw";

const SideNav = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  let lang = localStorage.getItem("lang");
  const cardsArray = ListaWystaw.map((ListaWystaw) => (
    // if (lang == "en") return <Row></Row>;
    // else
    //   return (

    <div className="sideNav hide-on-small-only">
      <img src={logo} width="100%" alt="" />
      <br />
      <br />
      <br />
      <br />
      <Link to="wystawa1" className="sideNavItem">
        Sala 1 <Divider />
      </Link>
      <br />
      <Link to="wystawa2" className="sideNavItem">
        Sala 2 <Divider />
      </Link>
      <br />
      <Link to="wystawa3" className="sideNavItem">
        Sala 3 <Divider />
      </Link>
      <br />
      <Link to="wystawa4" className="sideNavItem">
        Sala 4 <Divider />
      </Link>
      <br />

      <br />
      <br />
      <br />
      <br />
      <div className="left">Informacja RODO</div>
      <br />
      <div className="rodo left-align">
        <br />
        My i nasi partnerzy możemy uzyskać dodstęp do Twojego urządzenia, aby
        rozpoznać Cię za pomocą takich indentyfikatorów jak: pliki coockie i ip.
        Dane te wykorzystanę są tylko i wyłącznie w celu wyboru języka oraz
        usprawnienia działania strony (poprzez przechowywanie zdjęć, tekstu i
        filmów ,znajdujących na stronie, w pamięci podręcznej komputera).
      </div>
      <Link>więcej informacji</Link>
    </div>
  ));
  return (
    <div>
      <Row>{cardsArray}</Row>
    </div>
  );
};

export default SideNav;
