import db from "./firebase.config";
import React, { useState, useEffect } from "react";
import "materialize-css";
import { Modal, Card, Col, Row } from "react-materialize";
import Button from "react-materialize/lib/Button";
import "firebase/compat/firestore";
import "firebase/storage";
import "firebase/database";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";

const Write = () => {
  

  const login = () => {
    localStorage.setItem("pass", pass);
    window.location.reload(false);
  };
  let loggedin = localStorage.getItem("pass");
  const Fetchdata = async () => {
    await db
      .collection("wystawy")
      .get()
      .then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
      });
  };
  useEffect(() => {
    setTimeout(() => {
      Fetchdata();
    }, 1); //miliseconds
  }, []);
  const updateDATA1 = async () => {
    const ref = doc(db, "wystawy", "wystawy");
    await setDoc(
      ref,
      {
        OPISSHORT1: opisshort1,
        WYSTAWA1: wystawa1,
        AUTHOR1: author1,
        PICTURE1: picture1,
        PICTURE11: picture11,
        PICTURE12: picture12,
        PICTURE13: picture13,
        DATE1: date1,
      },
      { merge: true }
    );

    window.location.reload(false);
  };
  const updateDATA = async () => {
    const ref = doc(db, "wystawy", "wystawy");
    await setDoc(
      ref,
      {
        AUTHOR1: author1,
        AUTHOR2: author2,
        AUTHOR3: author3,
        AUTHOR4: author4,
        DATE1: date1,
        DATE2: date2,
        DATE3: date3,
        DATE4: date4,
        OPISSHORT1: opisshort1,
        OPISSHORT2: opisshort2,
        OPISSHORT3: opisshort3,
        OPISSHORT4: opisshort4,
        PICTURE1: picture1,
        PICTURE11: picture11,
        PICTURE12: picture12,
        PICTURE13: picture13,
        PICTURE2: picture2,
        PICTURE21: picture21,
        PICTURE22: picture22,
        PICTURE23: picture23,
        PICTURE3: picture3,
        PICTURE31: picture31,
        PICTURE32: picture32,
        PICTURE33: picture33,
        PICTURE4: picture4,
        PICTURE41: picture41,
        PICTURE42: picture42,
        PICTURE43: picture43,
        WYSTAWA1: wystawa1,
        WYSTAWA2: wystawa2,
        WYSTAWA3: wystawa3,
        WYSTAWA4: wystawa4,
      },
      { merge: true }
    );

    window.location.reload(false);
  };
  const [info, setInfo] = useState([]);
  const [pass, setPass] = useState();
  const [show, setShow] = useState();
  const [opisshort1, setOpisshort1] = useState();
  const [opisshort2, setOpisshort2] = useState();
  const [opisshort3, setOpisshort3] = useState();
  const [opisshort4, setOpisshort4] = useState();

  const [wystawa1, setWystawa1] = useState();
  const [wystawa2, setWystawa2] = useState();
  const [wystawa3, setWystawa3] = useState();
  const [wystawa4, setWystawa4] = useState();

  const [author1, setAuthor1] = useState();
  const [author2, setAuthor2] = useState();
  const [author3, setAuthor3] = useState();
  const [author4, setAuthor4] = useState();

  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [date3, setDate3] = useState();
  const [date4, setDate4] = useState();

  const [picture1, setPicture1] = useState();
  const [picture2, setPicture2] = useState();
  const [picture3, setPicture3] = useState();
  const [picture4, setPicture4] = useState();

  const [picture11, setPicture11] = useState();
  const [picture12, setPicture12] = useState();
  const [picture13, setPicture13] = useState();

  const [picture21, setPicture21] = useState();
  const [picture22, setPicture22] = useState();
  const [picture23, setPicture23] = useState();

  const [picture31, setPicture31] = useState();
  const [picture32, setPicture32] = useState();
  const [picture33, setPicture33] = useState();

  const [picture41, setPicture41] = useState();
  const [picture42, setPicture42] = useState();
  const [picture43, setPicture43] = useState();



  
  const items = info.map((data) => {
    if (show == "true") return (
      <Row>
                  
            <Row>
         <Row>
          <input
            placeholder={data.WYSTAWA1}
            onChange={(e) => setWystawa1(e.target.value)}
          />
          <input
            placeholder={data.OPISSHORT1}
            onChange={(e) => setOpisshort1(e.target.value)}
          />
          <input
            placeholder={data.AUTHOR1}
            onChange={(e) => setAuthor1(e.target.value)}
          />
          <input
            placeholder={data.DATE1}
            onChange={(e) => setDate1(e.target.value)}
          />
          <input
            placeholder={data.PICTURE1}
            onChange={(e) => setPicture1(e.target.value)}
          />
          <input
            placeholder={data.PICTURE11}
            onChange={(e) => setPicture11(e.target.value)}
          />
          <input
            placeholder={data.PICTURE12}
            onChange={(e) => setPicture12(e.target.value)}
          />
          <input
            placeholder={data.PICTURE13}
            onChange={(e) => setPicture13(e.target.value)}
          />

          <Button onClick={updateDATA1}>Zapisz</Button>
        </Row>
        <Row>
          <input
            placeholder={data.WYSTAWA2}
            onChange={(e) => setWystawa2(e.target.value)}
          />
          <input
            placeholder={data.OPISSHORT2}
            onChange={(e) => setOpisshort2(e.target.value)}
          />
          <input
            placeholder={data.AUTHOR2}
            onChange={(e) => setAuthor2(e.target.value)}
          />
          <input
            placeholder={data.DATE2}
            onChange={(e) => setDate2(e.target.value)}
          />
          <input
            placeholder={data.PICTURE2}
            onChange={(e) => setPicture2(e.target.value)}
          />
          <input
            placeholder={data.PICTURE21}
            onChange={(e) => setPicture21(e.target.value)}
          />
          <input
            placeholder={data.PICTURE22}
            onChange={(e) => setPicture22(e.target.value)}
          />
          <input
            placeholder={data.PICTURE23}
            onChange={(e) => setPicture23(e.target.value)}
          />

          <Button onClick={updateDATA}>Zapisz</Button>
        </Row>
        <Row>
          <input
            placeholder={data.WYSTAWA3}
            onChange={(e) => setWystawa3(e.target.value)}
          />
          <input
            placeholder={data.OPISSHORT3}
            onChange={(e) => setOpisshort3(e.target.value)}
          />
          <input
            placeholder={data.AUTHOR3}
            onChange={(e) => setAuthor3(e.target.value)}
          />
          <input
            placeholder={data.DATE3}
            onChange={(e) => setDate3(e.target.value)}
          />
          <input
            placeholder={data.PICTURE3}
            onChange={(e) => setPicture3(e.target.value)}
          />
          <input
            placeholder={data.PICTURE31}
            onChange={(e) => setPicture31(e.target.value)}
          />
          <input
            placeholder={data.PICTURE32}
            onChange={(e) => setPicture32(e.target.value)}
          />
          <input
            placeholder={data.PICTURE33}
            onChange={(e) => setPicture33(e.target.value)}
          />

          <Button onClick={updateDATA}>Zapisz</Button>
        </Row>
        <Row>
          <input
            placeholder={data.WYSTAWA4}
            onChange={(e) => setWystawa4(e.target.value)}
          />
          <input
            placeholder={data.OPISSHORT4}
            onChange={(e) => setOpisshort4(e.target.value)}
          />
          <input
            placeholder={data.AUTHOR4}
            onChange={(e) => setAuthor4(e.target.value)}
          />
          <input
            placeholder={data.DATE4}
            onChange={(e) => setDate4(e.target.value)}
          />
          <input
            placeholder={data.PICTURE4}
            onChange={(e) => setPicture4(e.target.value)}
          />
          <input
            placeholder={data.PICTURE41}
            onChange={(e) => setPicture41(e.target.value)}
          />
          <input
            placeholder={data.PICTURE42}
            onChange={(e) => setPicture42(e.target.value)}
          />
          <input
            placeholder={data.PICTURE43}
            onChange={(e) => setPicture43(e.target.value)}
          />

          <Button onClick={updateDATA}>Zapisz</Button>
        </Row>
       </Row>  



      </Row>
    );
    else return(
      <Button
              className="red"
              node="a"
              onClick={(e) => {
                setShow("true");
                setOpisshort1(data.OPISSHORT1);
                setOpisshort2(data.OPISSHORT2);
                setOpisshort3(data.OPISSHORT3);
                setOpisshort4(data.OPISSHORT4);
                setWystawa1(data.WYSTAWA1);
                setWystawa2(data.WYSTAWA2);
                setWystawa3(data.WYSTAWA3);
                setWystawa4(data.WYSTAWA4);
                setAuthor1(data.AUTHOR1);
                setAuthor2(data.AUTHOR2);
                setAuthor3(data.AUTHOR3);
                setAuthor4(data.AUTHOR4);
                setDate1(data.DATE1);
                setDate2(data.DATE2);
                setDate3(data.DATE3);
                setDate4(data.DATE4);
                setPicture1(data.PICTURE1);
                setPicture2(data.PICTURE2);
                setPicture3(data.PICTURE3);
                setPicture4(data.PICTURE4);
                setPicture11(data.PICTURE11);
                setPicture12(data.PICTURE12);
                setPicture13(data.PICTURE13);
                setPicture21(data.PICTURE21);
                setPicture22(data.PICTURE22);
                setPicture23(data.PICTURE23);
                setPicture31(data.PICTURE31);
                setPicture32(data.PICTURE32);
                setPicture33(data.PICTURE33);
                setPicture41(data.PICTURE41);
                setPicture42(data.PICTURE42);
                setPicture43(data.PICTURE43);
              }
            
            }
            >
              Edytuj
       </Button>
    )
  });
  if (loggedin == "Haslo2022") {
    return (
      <Row>
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Col s={10} l={8} push="s1 l2">
          <Row></Row>
          <Row></Row>
          <Row className="white">
            <Col s={12}>{items}</Col>
          </Row>
        </Col>
      </Row>
    );
  } else
    return (
      <Row><Col s={10} l={6} push="s1 l3">
        <Row />
        <Row />
        <Row />
        <Row />
        <Row /> <Row />
        <Row />
        <Row />
        <Card>
          <input
            type="password"
            placeholder="haslo"
            onChange={(e) => setPass(e.target.value)}
          />
          <Button className="red" onClick={login}>
            Zaloguj
          </Button>
        </Card>
        </Col>
      </Row>
    );
};

export default Write;
