import db from "./firebase.config";
import React, { useState, useEffect } from "react";
import "materialize-css";
import {
  Col,
  Row,
  Card,
  Button,
  Caption,
  Slide,
  Slider,
  Divider,
} from "react-materialize";
import { Link } from "react-router-dom";

const Read = () => {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      Fetchdata();
    }, 1); //miliseconds
  }, []);
  //  window.addEventListener("load", () => {
  //     Fetchdata();
  //   });

  // Fetch the required data using the get() method
  const Fetchdata = async () => {
    await db
      .collection("wystawy")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
      });
  };

  const items = info.map((data) => {
    return (
      <Slider
        fullscreen={true}
        options={{
          duration: 500,
          height: 400,
          indicators: true,
          interval: 6000,
        }}
      >
        <Slide image={<img alt="" src={`${data.PICTURE1}`} />}>
          <Caption placement="right" className="homeCaption">
            <h3>
              {data.WYSTAWA1}
              <Divider />
            </h3>
            <h5 className=" ">{data.OPISSHORT1}</h5>
            <Link to="wystawa1" className="btn-large black z-depth-3">
              Więcej informacji
            </Link>
          </Caption>
        </Slide>
        <Slide image={<img alt="" src={`${data.PICTURE2}`} />}>
          <Caption placement="right" className="homeCaption">
            <h3>
              {data.WYSTAWA2}
              <Divider />
            </h3>
            <h5 className=" ">{data.OPISSHORT2}</h5>
            <Link to="wystawa2" className="btn-large black z-depth-3">
              Więcej informacji
            </Link>
          </Caption>
        </Slide>
        <Slide image={<img alt="" src={`${data.PICTURE3}`} />}>
          <Caption placement="right" className="homeCaption">
            <h3>
              {data.WYSTAWA3}
              <Divider />
            </h3>
            <h5 className=" ">{data.OPISSHORT3}</h5>
            <Link to="wystawa3" className="btn-large black z-depth-3">
              Więcej informacji
            </Link>
          </Caption>
        </Slide>
        <Slide image={<img alt="" src={`${data.PICTURE4}`} />}>
          <Caption placement="right" className="homeCaption">
            <h3>
              {data.WYSTAWA4}
              <Divider />
            </h3>
            <h5 className=" ">{data.OPISSHORT4}</h5>
            <Link to="wystawa4" className="btn-large black z-depth-3">
              Więcej informacji
            </Link>
          </Caption>
        </Slide>
      </Slider>
    );
  });

  return <Row>{items}</Row>;
};

export default Read;
